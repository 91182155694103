.simulation-header {
    background-color: #f5f3f5;
    width: 90%;
    margin: auto
}

.simulation-header .title {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    padding: 2rem;
    text-align: center;
    color: #272370;
    margin-bottom: 0
}

.simulations-section .simulation-select .simpleselect {
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem
}

.simulations-section .simulation-sidebar {
    display: none
}

.simulations-section .simulations {
    width: 90%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
    text-align: center
}

.simulations-section .simulations .simulation-blanket {
    background-color: rgba(39, 36, 112, .7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none
}

.simulations-section .simulations .simulation-blanket .simulation-video-box {
    display: flex;
    align-items: center;
    height: 100%;
    width: 90%;
    margin: auto;
    max-width: 800px;
    flex-direction: column;
    justify-content: center
}

.simulations-section .simulations .simulation-blanket .simulation-video-box .close-section {
    align-self: flex-end;
    color: #fff;
    cursor: pointer
}

.simulations-section .simulations .simulation-blanket .simulation-video-box .inner {
    width: 100%;
    box-shadow: 0px 7px 10px 5px rgba(0, 0, 0, .5)
}

.simulations-section .simulations .simulation {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, .2);
    margin-bottom: 1rem;
    background-color: #f3f4f5
}

.simulations-section .simulations .simulation.single {
    box-shadow: none;
    background-color: #fff
}

.simulations-section .simulations .simulation.single .content {
    font-size: 18px;
    font-weight: 300;
    line-height: 2
}

.simulations-section .simulations .simulation.single .poster {
    margin-top: 0
}

.simulations-section .simulations .simulation .poster {
    height: 50vw;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto
}

.simulations-section .simulations .simulation .poster .playhead .icon {
    font-size: 40px
}

.simulations-section .simulations .simulation .content {
    background: #f5f3f5;
    background: -moz-linear-gradient(left, rgba(245, 243, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(245, 243, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(245, 243, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f3f5', endColorstr='#ffffff', GradientType=1);
    padding: 1rem
}

.simulations-section .simulations .simulation .content .simulation-title {
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: #272370
}

.simulations-section .simulations .simulation .content .runtime {
    color: #999;
    font-size: 12px
}

.simulations-section .simulations .simulation .content .excerpt {
    color: #666;
    padding-top: 1rem;
    padding-bottom: 1rem
}

.simulation-tiles {
    width: 90%;
    margin: auto
}

.simulation-tiles .inner {
    display: flex;
    flex-direction: column
}

.simulation-tiles .inner .tile {
    padding: .5rem;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    height: 40vw;
    margin-bottom: .5rem
}

.simulation-tiles .inner .tile .text {
    padding-bottom: 1rem;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    text-align: center
}

.simulation-tiles .inner .tile a {
    color: #fff
}

.simulation-tiles .inner .tile a:after {
    content: '';
    border-left: 5px solid #fff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    display: inline-block;
    margin-left: 1rem
}

@media (min-width:768px) {
    .simulation-tiles {
        max-width: 1400px;
        margin: auto
    }
    .simulation-tiles .inner {
        flex-direction: row
    }
    .simulation-tiles .inner .tile {
        width: 50%;
        height: 250px;
        border-right: 1px solid #fff
    }
    .simulation-tiles .inner .tile .text {
        font-size: 24px
    }
}

@media (min-width:992px) {
    .simulation-header {
        max-width: 1400px;
        margin-top: 50px
    }
    .simulations-section {
        display: flex;
        width: 90%;
        max-width: 1400px;
        margin: auto
    }
    .simulations-section .simulation-select {
        display: none
    }
    .simulations-section .simulation-sidebar {
        display: flex;
        width: 30%;
        border-right: 1px solid #f5f3f5;
        padding-top: 1rem;
        padding-right: 2rem;
        flex-direction: column
    }
    .simulations-section .simulation-sidebar .simulation-sidebar-link {
        position: relative
    }
    .simulations-section .simulation-sidebar .simulation-sidebar-link.red:after {
        content: 'Â»';
        position: absolute;
        right: -10px;
        display: inline-block;
        top: 0;
        bottom: 0
    }
    .simulations-section .simulation-sidebar .simulation-sidebar-link.red p {
        color: #dc6258
    }
    .simulations-section .simulation-sidebar .simulation-sidebar-link p {
        cursor: pointer;
        display: inline-block;
        color: #272370
    }
    .simulations-section .simulation-sidebar .simulation-sidebar-link p:hover {
        text-decoration: underline
    }
    .simulations-section .simulations {
        width: 70%;
        padding-left: 1rem;
        padding-top: 1rem
    }
    .simulations-section .simulations .simulation {
        display: flex
    }
    .simulations-section .simulations .simulation.hidden {
        display: none
    }
    .simulations-section .simulations .simulation .poster {
        width: 33%;
        height: 30vw
    }
    .simulations-section .simulations .simulation .content {
        width: 75%
    }
    .simulations-section .simulations .simulation .content .simulation-title {
        font-size: 24px
    }
    .simulations-section .simulations.wide {
        width: 90%;
        max-width: 800px
    }
    .simulations-section .simulations.wide .simulation .poster {
        height: 275px
    }
    .simulations-section .simulations.wide .simulation .content {
        padding-top: 0;
        background: #fff
    }
    .simulation-tiles {
        padding-top: 1rem
    }
}

@media (min-width:1200px) {
    .simulations-section .simulation-sidebar {
        padding-top: 2rem
    }
    .simulations-section .simulations {
        padding-top: 2rem;
        padding-left: 2rem
    }
    .simulations-section .simulations .simulation .poster {
        height: 22vw;
        max-height: 300px
    }
}

@media (min-width:1600px) {
    .list-hero .content .previous-simulations {
        padding-top: 1rem
    }
    .simulations-section .simulations.wide {
        padding-top: 0;
        margin-top: -4rem;
        z-index: 1
    }
}