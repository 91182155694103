
@import './assets/css/autoptimize.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'https://cdn.datatables.net/1.10.16/css/jquery.dataTables.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~video-react/dist/video-react.css';
@import './assets/css/fontastic.css';
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,700';
@import './assets/css/style.default.css';
@import './assets/css/custom.css';


@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto"),
   url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto"),
   url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto"),
   url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Roboto-Bold-In-Side-Bar";
  src: local("Roboto"),
   url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}