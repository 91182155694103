.heading {
  font-family: "Roboto-Bold";
  font-size: 1.3rem;
}

.headingInSideBar {
  font-family: "Roboto-Bold-In-Side-Bar";
  font-size: 1.1rem;
}

.sub-heading {
  font-family: "Roboto-Medium";
  font-size: 1.1rem;
}

.content-body,
.label {
  font-family: "Roboto-Regular";
  font-size: 0.9rem;
}

.breadcrumb-list {
  font-family: "Roboto-Regular";
  font-size: 1rem;
}

.table-heading {
  font-family: "Roboto-Medium";
  font-size: 1rem;
}

.left-menu-nav-bar {
  font-family: "Roboto-Regular";
  font-size: 1rem;
}

.buttons {
  font-family: "Roboto-Regular";
  font-size: 1rem;
}

.header {
  font-family: "Roboto-Medium";
  font-weight: 500;
}

.fields-font {
  font-family: "Roboto-Regular";
  font-size: 1rem;
}

.table-heading-in-dashboard {
  font-family: "Roboto-Medium";
  font-size: 0.9rem;
}

.sim-name {
  font-size: 2rem;
}

.sim-title {
  font-family: "Roboto-Regular";
  font-size: 40px;
  color: #272370;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
  font-family: "Roboto-Regular";
}

.span {
  background-color: lightgray;
  color: black;
}

.rounded-icon {
  color: red;
  margin-left: 20px;
}

.dialog-button {
  background-color: red !important;
  color: white !important;
}

.dialog-button-skip-for-now {
  background-color: #272370 !important;
  color: white !important;
  font-weight: bold !important;
  border-color: #272370 !important;
  float: left !important;
  text-transform: unset !important;
}

.dialog-button-submit {
  background-color: #dc6258 !important;
  color: white !important;
  font-weight: bold !important;
  border-color: #272370 !important;
  text-transform: unset !important;
}

.dialog-title {
  margin-left: -15px;
}

.sims-rounded-icon {
  color: cadetblue;
  margin-left: 20px;
}

.sims-dialog-button {
  background-color: cadetblue !important;
  color: white !important;
}

.sims-dialog-title {
  margin-left: -15px;
}

.datePicker {
  width: 798px;
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.msg-error {
  color: red;
}

.profile-container {
  position: relative;
  width: 100%;
  max-width: 120px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.profile-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100% !important;
  width: 100% !important;
  opacity: 0;
  transition: 0.3s ease;
  background-color: black;
  border-radius: 57px;
}

.profile-container:hover .profile-overlay {
  opacity: 0.4;
}

.edit-profile-icon {
  color: black;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.profile-dropdown-content {
  margin-left: 59px !important;
  margin-right: auto;
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 20px;
  margin-left: 10px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.profile-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none !important;
  display: block;
}

.profile-link:hover {
  text-decoration: none !important;
  color: black !important;
  background-color: #ddd !important;
}

.profile-image {
  width: 120px !important;
  height: 120px !important;
}

.button-image {
  border-color: #1154f2;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 13px;
  text-decoration: none;
  margin-left: 85px;
  color: var(--ds-text, #42526e) !important;
}
.button-image:hover {
  background: linear-gradient(to bottom, #eeeded 5%, #f8f6f7 100%);
  border-color: #f8f6f7;
}
.button-image:active {
  position: relative;
  top: 1px;
}

.button-options-upload {
  color: #272370;
  background-color: #ffffff;
  border-color: #272370;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 6px 12px;
  margin-left: 10px;
  text-decoration: none;
}

.button-options-upload:hover {
  color: #ffffff;
  background-color: #272370;
  border-color: #272370;
}

.button-options-cancel {
  color: #dc6258;
  background-color: #ffffff;
  border-color: #dc6258;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 6px 12px;
  margin-left: 10px;
  text-decoration: none;
}

.button-options-cancel:hover {
  color: #ffffff;
  background-color: #dc6258;
  border-color: #dc6258;
}

.button-align {
  margin-left: 160px;
}

/* for help icon  */
.help-icon {
  display: flex;
  justify-content: right;
}

.page-container-sim-detail {
  position: relative;
  min-height: 100vh;
}

.icons-sim-detail {
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 20px;
  padding-bottom: 3.5rem;
}
/* help icon in footer and avatar drop down  */
.icon-setting-for-footer {
  margin-left: 0.5rem;
  display: inline;
}

.logout {
  color: black !important;
  margin-left: 1px;
}

.logout:hover {
  text-decoration: none !important;
  color: red !important;
}

.profile-icon {
  color: black !important;
  margin-left: -20px;
}

.profile-icon:hover,
.edit-profile-icon:hover {
  color: red !important;
  text-decoration: none !important;
}

.help-icon-header {
  margin-right: 5px;
  margin-top: 11px;
  margin-bottom: 10px;
  color: rgb(99, 95, 95) !important;
}

/* detail simualtion header */
.footer-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 6px !important;
  font-size: 16px !important;
}

.help-icon-clr-view-sim {
  color: black !important;
}

/* view simulation page help icon */
.help-icon-view {
  margin-left: 15px;
  margin-right: 12px;
  margin-top: -5px;
}

/* acatar menu item */
.MuiListItem-root.Mui-selected {
  background-color: white !important;
}

.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.captialize {
  text-transform: capitalize;
}

.sim-play-type {
  font-family: "Roboto-Regular";
  font-size: 1.2rem;
}

.search-box-view {
  border-radius: 20px;
  width: 96%;
  border: solid rgb(206, 203, 203);
  padding-left: 15px;
}

.mainSearchBoxDiv {
  display: flex;
}

.searchBtn {
  border-radius: 0 30px 30px 0;
  border: solid rgb(206, 203, 203);
  margin-left: -20px;
  color: #dc6258;
  font-size: 16px;
  cursor: pointer;
  max-width: 80%;
  background-color: #fff;
}

.form-active {
  padding: 4px 0px 0px 8px;
  border-color: hsl(0, 0%, 80%);
}

.table_size_per_page_text {
  background: none;
  border: medium none;
  padding: 0px;
}

.table_size_per_page_input {
  width: 70px;
  flex: 0 0 auto;
  margin: 0px 10px;
  display: inline-block;
  float: none;
}

.table_header_color {
  color: #2196f3;
  cursor: pointer;
}

.sort_symbol > img {
  width: 16px;
}

th.sortable[aria-label~="sort"] {
  display: flex;
  justify-content: space-between;
}
.form-active:hover {
  border-color: hsl(0, 0%, 70%) !important;
}

.error {
  color: red;
  margin-bottom: 5px;
}

.page-item.active .page-link {
  z-index: initial !important;
}

.dashboard-sims-played {
  color: #272370 !important;
}

.ck-editor__editable_inline {
  min-height: 140px;
}

.custom-form-control {
  display: flex;
  flex-direction: column;
}

.custom-radio-group {
  display: flex;
  flex-direction: column;
  gap: 1.21rem;
}

.custom-radio-option {
  display: flex;
  align-items: center;
}

.custom-radio-input {
  margin-right: 0.5rem;
  vertical-align: middle;
  position: relative;
  top: -3px;
  transform: scale(1.3);
}

.custom-radio-input:checked {
  accent-color: #3f51b5;
}

.custom-radio-label {
  font-size: 1.1rem;
  cursor: pointer;
  line-height: 1;
}

.white_space_preserve {
  white-space-collapse: preserve;
}
